<template>
  <div>
    <p class="question">Q: What can I expect from my first session?</p>
    <p>
      A: Clients often feel anxious or uncertain about the first therapy session. I work to create a safe,
      respectful place for you to talk about anything that you need to discuss. The first several sessions are
      “getting to know you” sessions where I will ask you questions about yourself, such as your current
      symptoms, background, psychiatric history, and what you would like to work on. You may have questions
      you want to ask me, too. As we work together, we will develop a plan for changes you want to make and
      how therapy can support those changes.
    </p>
    <p class="question">Q: How long will therapy take?</p>
    <p>
      A: Therapy sessions are about 50 minutes long and generally occur weekly. The length of therapy depends
      on many factors including the issues you are seeking help with. As you make progress, your priorities
      may change. We will regularly discuss how therapy is progressing to make sure your priorities are being
      addressed effectively.
    </p>
    <p class="question">Q: How frequently will we meet?</p>
    <p>
      A: Generally, sessions occur weekly, particularly at the beginning. Presenting concerns, stressors,
      financial considerations, and therapist availability can affect session frequency. Session frequency can
      change over time depending on your needs. Less frequent sessions may make sense as you make progress
      toward your goals. Alternatively, if you notice an increase in stressors or symptoms, we might meet more
      frequently.
    </p>
    <p class="question">Q: Do you accept any insurance plans?</p>
    <p>
      A: I am credentialed with several insurance plans. Please check my Psychology Today profile to see an
      up-to-date list of which insurance plans I accept. You can also check your benefits by accessing my
      Headway website, located on my Psychology Today profile page. You will be responsible for any copay at
      the time of service.
    </p>
    <p class="question">Q. What if you do not accept my insurance? ?</p>
    <p>
      A: If I do not accept your insurance plan, you may want to work with me as an out-of-network provider. I
      provide my out-of-network clients with a billing statement that can be submitted to their insurance
      company for out-of-network reimbursement. It is strongly recommended that you check with your insurance
      company regarding their policy for out-of-network benefits. You may be able to get part of the cost of
      our sessions reimbursed directly to you. You will be responsible for the full cost at the time of the
      session.
    </p>
    <p class="question">Q: Do you meet with clients in-person or virtually (telehealth or teletherapy)?</p>
    <p>
      A: I offer both in-person and virtual therapy. For telehealth, I use a secure, HIPAA-compliant,
      telehealth platform called Doxy.me. It is very easy to use – you do not need to download any software. I
      will provide you with a link that gives you direct access to my “waiting room” on this platform. Since
      not all insurance providers reimburse for telehealth, you should clarify your insurance plan benefits if
      you wish to submit to them for this service.
    </p>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'FAQ - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'FAQ' }],
    };
  },
};
</script>
<style>
.question {
  font-weight: bold;
}
</style>
