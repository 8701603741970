<template>
  <div class="row">
    <div class="col-sm-6 mb-3">
      <img src="@/assets/images/lake.jpg" style="width: 100%" />
    </div>
    <div class="col-sm-6">
      <p>
        Dr. Parkhurst offers individual psychotherapy with adults (18 years and older) who are experiencing a
        broad range of concerns including anxiety, depression, stress, trauma, and relationship issues. These
        may be due to ongoing concerns or situational factors. Dr. Parkhurst has experience supporting clients
        through transitions and life changes. In addition, she works with individuals struggling with
        attention, organization, and concentration skills.
      </p>
      <p>
        Services are provided both in-person and via teletherapy, depending on preference and availability
      </p>
      <br />
      <p>
        Fees: $225.00 per 50-minute session. If you are using an insurance company with whom I am
        credentialed, you will be responsible for the copay at the time of service. In the event you are
        opting out to see me out-of-network, payment for the full amount is due at the time of service. (See
        FAQs for more information.) I accept payment by check, Venmo, and Zelle.
      </p>
      <p>
        Insurance: Please refer to my Pyschology Today profile page to determine which insurance plans I am
        credentialed with. Information specific to your plan can be obtained by clicking on my Headway
        website, found on my Psychology Today profile page.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: 'Services - Dr. Parkhurst',
      meta: [{ property: 'og:keywords', content: 'Services' }],
    };
  },
};
</script>
