import Vue from 'vue';
import VueRouter from 'vue-router';

import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import FAQ from '../views/FAQ.vue';
import Forms from '../views/Forms.vue';
import Home from '../views/Home.vue';
import Services from '../views/Services.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/forms',
    name: 'Forms',
    component: Forms,
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
  },
];

const router = new VueRouter({
  mode: 'history',
  //base: '/parkhurst-demo/',
  routes,
});

export default router;
