<template>
  <div id="app" class="container">
    <div id="header">
      <router-link to="/">
        <div class="initials">SP</div>
        <div class="name">Stephanie Parkhurst, PhD, LLC</div>
      </router-link>
      <div class="row">
        <div class="col-12">
          <hr class="hr1" />
          <hr class="hr2" />
        </div>
      </div>
      <div class="row justify-content-md-center contact">
        <div class="col-md-auto address">
          <a href="mailto:parkhurstphd@gmail.com" target="blank">parkhurstphd@gmail.com</a>
        </div>
        <div class="col-sm-auto">|</div>
        <div class="col-md-auto address">
          <a href="http://maps.google.com/?q=14919 Windmill Terrance, Silver Spring, MD 20905" target="blank"
            >14919 Windmill Terrance, Silver Spring, MD 20905</a
          >
        </div>
        <div class="col-sm-auto">|</div>
        <div class="col-md-auto address">
          <a href="tel:240-801-6998" target="blank">240.801.6998</a>
        </div>
      </div>
    </div>
    <div id="nav">
      <router-link to="/">Home</router-link> | <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link> | <router-link to="/faq">FAQ</router-link> |
      <router-link to="/forms">Forms</router-link> |
      <router-link to="/services">Services</router-link>
    </div>
    <keep-alive>
      <perfect-scrollbar ref="scroll" :options="{ suppressScrollX: true }">
        <router-view style="text-align: left; padding: 20px; padding-top: 0px" />
      </perfect-scrollbar>
    </keep-alive>
    <footer class="footer mt-auto">
      <div class="container text-center">
        Copyright &#169; Stephanie Parkhurst 2020-2025. All Rights Reserved.
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  watch: {
    $route() {
      this.$refs.scroll.$el.scrollTop = 0;
    },
  },
  metaInfo() {
    return {
      title: 'Dr. Parkhurst',
      meta: [
        {
          name: 'description',
          content:
            'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.',
        },
        { property: 'og:title', content: 'Dr. Parkhurst' },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:keywords',
          content: 'Dr. Parkhurst, Stephanie Parkhurst, Therapy, Licensed Therapist Psychologist',
        },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
};
</script>
<style>
.ps {
  height: calc(100% - 240px);
}

.ps .ps__rail-y {
  background-color: #eee;
  opacity: 0.9;
  width: 10px;
}
</style>
